import { __awaiter, __generator } from "tslib";
import { reactive, defineComponent, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { useField, useForm } from "vee-validate";
import * as yup from "yup";
import LanguageUtil from "@/commons/LanguageUtil";
import TeamInteractor from "@/domain/usecases/TeamInteractor";
import DIContainer from "@/core/DIContainer";
import SkillTagPreloader from "@/presentation/components/preloader/skill-tag-preloader/SkillTagPreloader.vue";
import InputPreload from "@/presentation/components/preloader/input-preloader/InputPreloader.vue";
import RoutePath from "@/commons/RoutePath";
import CommonService from "@/services/CommonService";
import ErrorMessage from "@/presentation/components/error-message/ErrorMessage.vue";
import CurrencyInput from "@/presentation/components/currency-input/CurrencyInput.vue";
import * as agh from "agh.sprintf";
import Textarea from "@/presentation/components/textarea/Textarea.vue";
export default defineComponent({
    name: "FormApply",
    props: ["listBreadcrumb"],
    components: {
        SkillTagPreloader: SkillTagPreloader,
        InputPreload: InputPreload,
        ErrorMessage: ErrorMessage,
        CurrencyInput: CurrencyInput,
        Textarea: Textarea
    },
    setup: function (props) {
        var store = useStore();
        var router = useRouter();
        var route = useRoute();
        var teamInteractor = DIContainer.instance.get(TeamInteractor);
        var _a = useForm({
            initialValues: {
                nick_name: "",
                estimate_price: "",
                estimate_price_description: "",
                user_relations_description: "",
                account_id: null,
                team_id: ""
            }
        }), values = _a.values, setValues = _a.setValues, errors = _a.errors, validate = _a.validate;
        var state = reactive({
            aLang: LanguageUtil.getInstance().lang,
            listTeam: [],
            preload: true,
            projectInfo: {},
            preloadInfo: true,
        });
        var projectId = null;
        var nick_name = useField("nick_name", yup.string()).value;
        var estimate_price = useField("estimate_price", function (val) {
            if (!val && val !== 0) {
                return state.aLang.validation_required;
            }
            var numberRegex = /^\d*$/;
            if (!numberRegex.test(val) || (numberRegex.test(val) && (parseInt(val) < 0 || Number(values.estimate_price).toString().length > 9) || !numberRegex.test(Number(values.estimate_price).toString()))) {
                return state.aLang.currency_max_length;
            }
            return true;
        }).value;
        var estimate_price_description = useField("estimate_price_description", yup
            .string()
            .max(1000, agh.sprintf(state.aLang.validation_max_length, 1000))).value;
        var user_relations_description = useField("user_relations_description", yup
            .string()
            .max(10000, agh.sprintf(state.aLang.validation_max_length, 10000))).value;
        var account_id = useField("account_id", yup.number().nullable()).value;
        var team_id = useField("team_id", yup.string()).value;
        function submit() {
            return __awaiter(this, void 0, void 0, function () {
                var check, data;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, validate()];
                        case 1:
                            check = _a.sent();
                            if (!check) {
                                return [2 /*return*/];
                            }
                            data = Object.assign({}, values);
                            data.estimate_price = Number(data.estimate_price);
                            store.commit("setProjectApply", data);
                            router.push("/project/" + projectId + "/apply/confirm");
                            return [2 /*return*/];
                    }
                });
            });
        }
        ;
        function onBack() {
            return router.push(RoutePath.PROJECT_DETAIL + projectId);
        }
        onMounted(function () {
            var data = store.state.projectApply;
            if (data.nick_name &&
                data.account_id) {
                data.estimate_price = Number(data.estimate_price) >= 0 ? data.estimate_price.toString() : "";
                setValues(data);
                state.preloadInfo = false;
            }
            else {
                var user = CommonService.getCookie("user");
                var user_infor = user ? JSON.parse(decodeURIComponent(user)) : null;
                setValues({
                    nick_name: user_infor && user_infor.nick_name ? user_infor.nick_name : "",
                    account_id: user_infor && user_infor.account_id ? user_infor.account_id : ""
                });
            }
            projectId = route.params.projectId;
            state.preload = true;
            props.listBreadcrumb[2] = {
                name: "プロジェクト応募",
                route: ""
            };
            store.subscribe(function (mutation, stateVuex) {
                var _a;
                if (mutation.type === "setProjectInfo") {
                    state.projectInfo = stateVuex.projectInfo;
                    state.projectInfo.skills = (_a = state.projectInfo.skills) !== null && _a !== void 0 ? _a : [];
                    state.preloadInfo = false;
                }
            });
            teamInteractor.getTeamOwnedSimple().then(function (result) {
                state.preload = false;
                if (!result.data) {
                    return;
                }
                state.listTeam = result.data;
            }).catch(function (error) {
                state.preload = false;
            });
        });
        return {
            state: state,
            values: values,
            errors: errors,
            submit: submit,
            onBack: onBack,
            RoutePath: RoutePath
        };
    }
});
