<template>
  <div class="form_apply">
    <div class="form_apply__content">
      <h3>{{state.aLang.US017_project_apply_title}}</h3>
      <p class="signate">
        ご利用いただく上での基本情報をご入力ください。 SINGATE <br />
        IDで登録された情報を反映する場合は、右の「反映」ボタンを押してください。
      </p>
      <div class="form_apply__content__form">
        <form class="horizontal" action="">
          <div class="form-ctrl">
            <label class="text" >{{state.aLang.US017_nickname}}</label>
            <div class="form-group">
              <span class="name" v-if="!state.preloadInfo">{{values.nick_name}}</span>
              <InputPreload v-else/>
            </div>
          </div>
          <div class="form-ctrl">
            <label class="text">{{state.aLang.US017_estimate_price}}</label>
            <div class="form-group">
              <CurrencyInput
                class="width-300"
                placeholder="ご入力ください"
                v-model="values.estimate_price"
                v-if="!state.preload"/>
              <InputPreload class="width-300" v-else/>
              <ErrorMessage v-if="errors.estimate_price" :message="errors.estimate_price"/>
            </div>
          </div>
          <div class="form-ctrl">
            <label class="text">{{state.aLang.US017_estimate_price_description}}</label>
            <div class="form-group">
              <Textarea
                maxlength="1000"
                v-model="values.estimate_price_description"
                placeholder="ご入力ください"
                rows="5"
                v-if="!state.preload">
              </Textarea>
              <InputPreload type="textarea" v-else/>
              <ErrorMessage v-if="errors.estimate_price_description" :message="errors.estimate_price_description"/>
            </div>
          </div>
          <div class="form-ctrl">
            <label class="text question">{{state.aLang.US017_participant}}</label>
            <div class="form-group">
              <div class="form-row">
                <select name="" id="" class="width-300" v-model="values.team_id" v-if="!state.preload">
                  <option value="">個人で応募</option>
                  <option v-for="team of state.listTeam" :key="team.team_id" :value="team.team_id">{{team.team_name}}</option>
                </select>
                <InputPreload class="width-300" v-else/>
                <router-link :to="RoutePath.CREATE_TEAM">{{state.aLang.US017_create_team}}</router-link>
              </div>
            </div>
          </div>
          <div class="form-ctrl">
            <label class="text question" >{{state.aLang.US017_user_relation_description}}</label>
            <div class="form-group">
              <Textarea
                maxlength="10000"
                name=""
                id=""
                rows="5"
                placeholder="ご入力ください"
                v-if="!state.preload"
                v-model="values.user_relations_description"
              ></Textarea>
              <InputPreload type="textarea" v-else/>
              <ErrorMessage v-if="errors.user_relations_description" :message="errors.user_relations_description"/>
            </div>
          </div>
        </form>
      </div>
      <hr />
      <h3 class="skill-title">{{state.aLang.US017_important_infor}}</h3>
      <p class="skill-note">
        この企業は下記の情報を重視しています。最新の情報にアップデートしておくことで採用の可能性が高まります。可能な限り応募の前に更新しましょう。<br />
        <router-link :to="RoutePath.USER_PROFILE + '/' + values.account_id">プロフィールページへ</router-link>
      </p>
      <div class="form_apply__content__list_skills" v-if="!state.preloadInfo">
        <div class="skill-tag" v-for="skill of state.projectInfo.skills" :key="skill.id">{{skill.name}}</div>
      </div>
      <SkillTagPreloader v-else class="preload_list_skill" :number="10"/>
      <div class="btn_group">
          <button class="btn-md btn-light-gray shadow" type="button" @click="onBack">{{state.aLang.US017_cancel_button}}</button>
          <button class="btn-md btn-blue shadow" type="button" @click="submit">{{state.aLang.US017_submit_button}}</button>
      </div>
    </div>
  </div>
</template>

<script lang="ts" src="@/presentation/views/user/project-apply/form-apply/FormApply.ts">
</script>

<style lang="scss" src="@/presentation/views/user/project-apply/form-apply/FormApply.scss" scoped>
</style>
